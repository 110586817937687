<template>
  <div class="order container">
    <div class="header_gruop page_width flex_sb">
      <div class="icon_home flex_center" @click="toBack()"><svg t="1729563443244" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6080" id="mx_n_1729563443245" width="16" height="16"><path d="M398.912 513.152l377.28-384.256c27.904-28.416 27.904-74.432 0-102.784-27.84-28.352-73.024-28.352-100.8 0L247.808 461.504C233.792 475.776 226.88 494.464 226.944 513.152 226.88 531.84 233.792 550.464 247.808 564.736l427.584 435.392c27.84 28.352 73.024 28.352 100.8 0 27.904-28.352 27.904-74.368 0-102.656L398.912 513.152z" fill="#16181c" p-id="6081"></path></svg></div>
      <div class="header_title">Confirm request</div>
      <div></div>
    </div>
 
    <el-tabs v-model="activeName" @tab-click="clickTab">
        <el-tab-pane :label="tab.label" :name="tab.name" v-for="(tab,index) in tabOptions" :key="index" ></el-tab-pane>
    </el-tabs>
    <div class="scoller grid_container flex_col" style="overflow:auto;-webkit-overflow-scrolling: touch" v-infinite-scroll="loadMore" infinite-scroll-distance="1" :infinite-scroll-immediate="false">
        <div class="order_content">
            
            <div class="order_empty flex_col_center" v-if="orderList.length==0">
                <div class="empty_img">
                    <img src="https://global-cdn.bigplayers.com/m/img/icon/order-list-empty.png?t=2024102117" alt="">
                </div>
                <div class="empty_text">There is no order data at the moment. See other products</div>
                <div class="empty_btn" @click="toPage('/index')">Look around</div>
            </div>
            <div class="order_list page_width" v-else>
                <div class="card_order" v-for="(item,index) in orderList" :key="index">
                    <div class="order_head flex_sb">
                        <span style="color:#909194">Application number:</span>
                        <span style="color:#f77e1a">Getting paid</span>
                    </div>
                    <div class="order_head flex_sb">
                        <span style="color:#161619">{{ item.orderNo }}</span>
                        <span style="color:#909194">{{ item.createTime }}</span>
                    </div>
                    <div class="order_thumb flex">
                        <div class="order_img">
                            <img :src="item.image" alt="">
                        </div>
                        <div class="order_msg">
                            <div class="order_title"> {{ item.name }}</div>
                            <!-- <div class="order_type">Express Supply Pass</div> -->
                            <div class="order_info">
                                <div class="order_counnt">X{{ item.quantity }}</div>
                                <div class="order_price"> {{currency.remark}} {{ item.unitPrice }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="order_head flex_sb">
                        <span style="color:#f77e1a;font-size: 14px;"></span>
                        <span style="color:#161619;font-size: 16px;">Amounts payable:{{currency.remark}} {{item.amount}}</span>
                    </div>
                    <div v-if="item.orderStatus==6 && item.remark">
                        <div class="order_head flex_sb" v-for="(i,i_index) in item.remark.deliveryInfo.errors" :key="i_index">
                            <span style="color:#f77e1a;font-size: 14px;"></span>
                            <span style="color:#f77e1a;font-size: 12px;">{{ i.message }}</span>
                        </div>
                    </div>
                    
                    <div class="btn_group flex_end" >
                        <!-- <div class="btn_serve">Customer Service</div> -->
                        <div class="btn_cancel" @click="toPage('/updateOrder?orderId='+item.id+'&isAgree='+'-1')" v-if="item.orderStatus==6&&item.remark">cancel</div>
                        <div class="btn_pay" @click="toPage('/updateOrder?orderId='+item.id+'&isAgree='+'0')" v-if="item.orderStatus==6&&item.remark">Modify</div>
                        <div class="btn_pay" @click="toPage('/payment?orderId='+item.orderNo)" v-if="item.status==0">Pay</div>
                    </div>
                    <!-- <div v-if="item.orderStatus==8||item.orderStatus==6">
                        <div class="err_text" style="text-align: left;padding:4px;font-size: 13px;" >{{ i.message }}</div>
                        <control-type :result="item.remark.deliveryInfo.sections"></control-type>
                    </div> -->
                </div>
            </div>
        </div>
        
    </div>
  </div>
</template>

<script>
import controlType from '../../components/controlType.vue';
import {divide} from '../../utils/tool'
export default {
    components:{
        controlType
    },
    data(){
        return{
            activeName:'first',
            tabOptions:[
                {label:'All',name:'first',status:-1,orderStatus:-1},
                {label:'Unpaid',name:'second',status:'0'},
                {label:'In processing',name:'third',status:3}, 
                {label:'Paid',name:'fourth',status:1},
                {label:'Payment failed',name:'five',status:2},
                {label:'created',name:'six',orderStatus:4,status:1},
                {label:'Confirmed',name:'seven',orderStatus:5,status:1},
                {label:'Delivering',name:'eight',orderStatus:6,status:1},
                {label:'Closed',name:'nine',orderStatus:7,status:1},
                {label:'Completed',name:'ten',orderStatus:8,status:1},
                {label:'Refunding',name:'tenOne',orderStatus:9,status:1},
                {label:'Refund completed',name:'tenWwo',orderStatus:10,status:1},
                
            ],
            orderList:[],
            pageIndex:1,
            pageSize:20,
            status:-1,
            orderStatus:-1,
            isLastPage:true
        }
    },
    created(){
        let status = this.$route.query.status?this.$route.query.status:-1
        let orderStatus = this.$route.query.orderStatus?this.$route.query.orderStatus:-1
        this.status = status
        this.orderStatus = orderStatus
        this.getDate()
    },
    methods:{
        toPage(path){
            this.$router.push(path)
        },
        toBack(){
        
          this.$router.back()
        },
        getDate(){
            let pageIndex = this.pageIndex
            let pageSize = this.pageSize
            let status = this.status
            let orderStatus = this.orderStatus
            this.$axiosApi.getOrderList({pageIndex,pageSize,status,orderStatus}).then(res=>{
                if(res.code == 200){
                   
                    res.data.list.map(v=>{
                        if(!/^\s*$/.test(v.remark)){
                            // console.log('vvvvvv',v.remark)
                            v.remark = JSON.parse(v.remark)
                        }
                      
                        v.unitPrice = divide(v.unitPrice)
                        v.amount = divide(v.amount)
                        
                        return v
                    })
                    this.orderList = res.data.list
                    setTimeout(()=>{
                        this.isLastPage = res.data.isLastPage
                    })
                    console.log('orderList',this.orderList)
                }
            })
        },
        clickTab(e){
            console.log(e.name)
            let name = e.name
            let tabOptions = this.tabOptions
            let status = -1
            let orderStatus = -1
            tabOptions.map(v=>{
                if(v.name == name){
                    v.status?status=v.status:status=-1
                    v.orderStatus?orderStatus=v.orderStatus:orderStatus=-1
                    
                }
            })
            this.orderStatus = orderStatus
            this.status=status
            this.getDate()
        },
        loadMore(){
            if(this.isLastPage){return}
            this.pageIndex = this.pageIndex+1
            this.getData()
            console.log('isLastPage',this.pageIndex,this.isLastPage )
        }
    },
    computed:{
        currency(){return this.$store.state.currency}
    }
}
</script>

<style lang="scss" scoped>
.order{
    background:#f6f6f8;
    .grid_container{
        max-height: calc(100% - 115px);
    }
    /deep/.tablist{
            display: flex;
            width:100%;
            justify-content: space-around;
            
        }
        /deep/.el-tabs__active-bar{
            background:#f77e1a;
            height: 4px;
            border-radius: 2px;
            // width: 80%!important;
            // left:10%!important;
        }
        /deep/.el-tabs__item.is-active{
        color:#f77e1a;
        // font-weight: bold;

        }
        /deep/.el-tabs__item{
        color:#909194;
        padding:0 16px;
        &:hover{
        
        }
        }
        /deep/.el-tabs__nav-scroll{
            overflow: auto!important;
        }
        /deep/.el-tabs__nav-wrap{
            overflow: auto!important;
        }
        /deep/.el-tabs__nav-prev{
            display: none;
        }
        /deep/.el-tabs__nav-next{
            display: none;
        }
        /deep/.el-tabs__nav-wrap{
        &:after{
        height: 0;
        }
        }
    .order_content{
        // margin-top:60px;
       
        .order_empty{
            margin-top:54px;
            .empty_img{
                width: 108px;
                height: 108px;
            }
            .empty_text{
                width:70%;
                color:#909194;
                font-size: 16px;
                margin-top:38px;
            }
            .empty_btn{
                color:#fff;
                background:#f77e1a;
                padding:10px 16px;
                margin-top:100px;
                border-radius: 30px;
                cursor: pointer;
            }
        }
        .order_list{
            padding-top:16px;
            .card_order{
                margin-bottom:10px;
                border-radius: 10px;
                background:#fff;
                padding:18px;
                .order_head{
                    font-size: 12px;
                    &:last-child{
                        margin-top:8px;
                    }
                }
                .order_thumb{
                    margin-top:20px;
                    .order_img{
                        width:118px;
                        height: 118px;
                        margin-right:8px;
                    }
                    .order_msg{
                        flex:1;
                        text-align: left;
                        .order_title{
                            font-size: 14px;
                            color:#222425;
                            margin-bottom:5px;
                        }
                        .order_type{
                            font-size: 12px;
                            color:#919296;
                            // margin-bottom:8px;
                        }
                        .order_info{
                            text-align: right;
                            .order_counnt{
                                color:#909194;
                                font-size: 12px;
                            }
                            .order_price{
                                font-size: 14px;
                                color:#161619;
                            }
                        }
                    }

                }
                .btn_group{
                    margin-top:10px;
                    .btn_serve{
                        background:#397aff;
                        color:#fff;
                        padding:8px 20px;
                        border-radius: 20px;
                        margin-right:10px;
                    }
                    .btn_cancel{
                        padding:4px 16px;
                        color:#909194;
                        border: 1px solid #eaeaea;
                        border-radius: 20px;
                        cursor: pointer;
                    }
                    .btn_pay{
                        margin-left:10px;
                        padding:4px 16px;
                        background:linear-gradient(0deg, #feba27, #fae44c);
                        border-radius: 20px;
                        cursor: pointer;
                    }
                }

            }
        }
    }
}

</style>